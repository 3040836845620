@font-face {
  font-family: "Microsoft YaHei";
}

body {
  font-family: Microsoft YaHei;
}

h2 {
  color: #e1dbcb;
}

p {
  margin-top: 20px;
  color: #e1dbcb;
}

.title-label {
  font-size: 1.8em;
  color: #e1dbcb;
  font-weight: 600;
}

.text-content {
  margin: 0 0 0 65px;
  max-width: 80%;
  line-height: 180%;
  font-size: 1.2em;
}

.foot-font {
  color: #c2baa3;
  margin-top: 0px;
}

.page-container {
  background-image: url("../img/main_content_bg.jpg");
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
}

.main-content-section {
  margin-top: 100px;
}

#land_page {
  width: 100%;
  z-index: -10;
}

.container-fluid {

}

.navbar-section {
  padding: 0px;
}

.landing-page-section {
  position: relative;
}

#play_btn {
  cursor: pointer;
}

#play_btn_image {
  position: absolute;
  bottom: 20%;
  left: 10%;
  width: 10%;
  max-width: 150px;
}

#navbar {
  background-image: url("../img/navbar-bg.png");
  height: 85px;
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
  width: 100%;
  margin: 0;
}

#navbar-bg {
  width: 100%;
  position: relative;
  top: -100px;
  z-index: -1;
}

@media screen and (min-width: 1570px) {
  #spelunkerImg {
    padding: 10px 0 5px 0;
    max-width:300px;
    width: 100%;
  }
}

@media screen and (max-width: 1570px) {
  #spelunkerImg {
    padding: 20px 0 5px 0;
    max-width:300px;
    width: 100%;
  }
}

.param-container {
  margin-bottom: 120px;
}

.param-container p:nth-child(3) {
  margin-top: 30px;
}

.foot-section {
  margin: 150px 0 50px 0;
}

.char {
  width: 25%;
  position: absolute;
  right: 10%;
  //z-index: -1;
}

.boy {
  top: 110%
}

.girl {
  top: 170%;
}